<template>

    <StaffList
        :heading="`Justice of the Peace Staff Directory`"
        :loading="$store.getters.loading"
        :staffList="list"></StaffList>

</template>

<script>
	import {getJPStaff} from '../lib/api'
    import {getPrivateJPStaff} from '../lib/private-api'
    import {debugMsg} from '../lib/helpers';

    import StaffList from '../components/layouts/StaffList'
	export default {
		name: "jp-page",
		components: {
            StaffList
		},

		data: () => ({
            list: [],
		}),

		methods: {

            /**
            * Request a list of availible staff based on a specified campus & 
            * building from the Staff directory API
            */
            requestJPStaff: function() {
                this.$store.commit('setLoading', true);

                if (this.$store.getters.isLoggedIn) {

                    this.$authService.getToken()                
                        .then( (accessToken) => {

                            getPrivateJPStaff(accessToken)
                                .then((res) => {

                                    this.successSearch(res);
                                })
                                .catch((err) => {
                                    this.errorSearch(err);
                                })
                        },
                        (error) => {
                            this.$store.commit("errorMsg", `There was an error retrieving results`);
                        });
                } else {
                    getJPStaff()
                        .then((res) => {

                            this.successSearch(res);
                        })
                        .catch((err) => {
                            this.errorSearch(err);
                        })
                }
            },

            /**
            * Handle a successful search response from the API
            */
            successSearch: function(res) {
                this.$store.commit('setLoading', false);
                // Check if API returned any results
                if (res.data.length == 0) {
                    this.$store.commit("errorMsg", "No results were found");
                } else {
                    // store result in vuex
                    this.$store.commit("updateStaff", res);

                    this.list = this.$store.getters.staff.map((staff) => {
                        return {
                            groupName: staff["Campus Name"],
                            staffList: Array.from(staff["Staff"])
                        };
                    });
                }
            },

            /**
            * Handle a successful search response from the API
            */
            errorSearch: function(err) {
                this.$store.commit('setLoading', false);
                this.$store.commit("errorMsg", `There was an error retrieving results`);
                this.errorLogin(err);
            },
		},


		computed: {
            
		},

        created: function() {
            this.requestJPStaff();
        }
	}
</script>